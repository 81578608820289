body splash-screen {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: #ffffff;
	background-image: var(--splash-screen-bg);
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	color: #f9fafb;
	z-index: 999999;
	pointer-events: none;
	opacity: 1;
	visibility: visible;
}

body splash-screen img {
	filter: brightness(100);
	width: 25%;
	max-width: 25%;
}

body splash-screen .spinner {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-top: 40px;
	width: 56px;
}

body splash-screen .spinner > div {
	width: 12px;
	height: 12px;
	background-color: #ffffff;
	border-radius: 100%;
	display: inline-block;
	-webkit-animation: bouncedelay 1s infinite ease-in-out both;
	animation: bouncedelay 1s infinite ease-in-out both;
}

body splash-screen .spinner .bbwidget-bounce1 {
	-webkit-animation-delay: -0.32s;
	animation-delay: -0.32s;
}

body splash-screen .spinner .bbwidget-bounce2 {
	-webkit-animation-delay: -0.16s;
	animation-delay: -0.16s;
}

@-webkit-keyframes bouncedelay {
	0%,
	80%,
	100% {
		-webkit-transform: scale(0);
	}
	40% {
		-webkit-transform: scale(1);
	}
}

@keyframes bouncedelay {
	0%,
	80%,
	100% {
		-webkit-transform: scale(0);
		transform: scale(0);
	}
	40% {
		-webkit-transform: scale(1);
		transform: scale(1);
	}
}

/* Daña el scroll del sitio web que muestra el widget */
/* body:not(.splash-screen-hidden) {
	overflow: hidden;
} */

body.splash-screen-hidden splash-screen {
	visibility: hidden;
	opacity: 0;
}

body.brandbot-widget-visible brandbot-widget {
	display: initial;
}

@media only screen and (max-width: 767px) {
	body splash-screen {
		background-image: var(--splash-screen-bg-mobile);
	}

	body splash-screen img {
		width: 75%;
		max-width: 75%;
	}
}
