/* You can add global styles to this file, and also import other style files */

@import "@angular/material/prebuilt-themes/deeppurple-amber.css";
@import "@angular/material/prebuilt-themes/indigo-pink.css";
@import "~@ctrl/ngx-emoji-mart/picker";

/* @import "quill/dist/quill.bubble.css";
@import "quill/dist/quill.core.css";
@import "quill/dist/quill.snow.css";

* {
	all: unset !important
} */

@import 'swiper/swiper-bundle.css';

@import 'assets/styles/quill.bubble.css';
@import 'assets/styles/quill.core.css';
@import 'assets/styles/quill.snow.css';

@tailwind base;
@tailwind components;
@tailwind utilities;


html,
body {
	height: 100%;
}

.bbchat-body {
	margin: 0px;
	overflow: hidden;
}

.mat-tooltip {
	font-size: 12px;
	width: auto !important;
}

.cdk-overlay-container {
	z-index: 9999 !important;
}

.cdk-overlay-backdrop {
	z-index: 9999 !important;
}

.cdk-overlay-connected-position-bounding-box {
	z-index: 9999 !important;
}

html,
brandbot-widget {
	font-family:
		ui-sans-serif,
		system-ui,
		-apple-system,
		BlinkMacSystemFont,
		"Segoe UI",
		Roboto,
		"Helvetica Neue",
		Arial,
		"Noto Sans",
		sans-serif,
		"Apple Color Emoji",
		"Segoe UI Emoji",
		"Segoe UI Symbol",
		"Noto Color Emoji" !important;
}

.mat-select-value-text {
	color: #000000 !important;
}

.mat-select-arrow {
	color: #000000 !important;
}

button:focus {
	outline: none !important;
}

::-webkit-scrollbar {
	width: 5px;
	height: 5px;
}

::-webkit-scrollbar-thumb {
	border-radius: 25px !important;
	background: #b8b9bb;
}

h1 {
	display: block !important;
	font-size: 32px !important;
	margin-top: 21.33px !important;
	margin-bottom: 21.33px !important;
	margin-left: 0 !important;
	margin-right: 0 !important;
	font-weight: bold !important;
	font-family:
		Roboto,
		Helvetica Neue,
		sans-serif !important; 
}

h2 {
	display: block !important;
	font-size: 24px !important;
	margin-top: 16px !important;
	margin-bottom: 16px !important;
	margin-left: 0 !important;
	margin-right: 0 !important;
	font-weight: bold !important;
	font-family:
		Roboto,
		Helvetica Neue,
		sans-serif !important; 
}

h3 {
	display: block !important;
	font-size: 20px !important;
	margin-top: 14px !important;
	margin-bottom: 14px !important;
	margin-left: 0 !important;
	margin-right: 0 !important;
	font-weight: bold !important;
	font-family:
		Roboto,
		Helvetica Neue,
		sans-serif !important; 
}

h4 {
	display: block !important;
	font-size: 18px !important;
	margin-top: 12px !important;
	margin-bottom: 12px !important;
	margin-left: 0 !important;
	margin-right: 0 !important;
	font-weight: bold !important;
	font-family:
		Roboto,
		Helvetica Neue,
		sans-serif !important; 
}

h5 {
	display: block !important;
	font-size: 16px !important;
	margin-top: 10px !important;
	margin-bottom: 10px !important;
	margin-left: 0 !important;
	margin-right: 0 !important;
	font-weight: bold !important;
	font-family:
		Roboto,
		Helvetica Neue,
		sans-serif !important; 
}

h6 {
	display: block !important;
	font-size: 14px !important;
	margin-top: 8px !important;
	margin-bottom: 8px !important;
	margin-left: 0 !important;
	margin-right: 0 !important;
	font-weight: bold !important;
	font-family:
		Roboto,
		Helvetica Neue,
		sans-serif !important; 
}

p span {
	font-size: 14px !important;
	font-family:
		Roboto,
		Helvetica Neue,
		sans-serif !important; 
} 

.ql-snow .ql-editor .ql-syntax {
	background-color: #f7e59d !important;
	border: 1px solid #f0c674 !important;
	color: #000000 !important;
	font-family:
		Roboto,
		Helvetica Neue,
		sans-serif !important; 
}

